import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { MsgExecuteContractEncodeObject } from "@cosmjs/cosmwasm-stargate";
import Long from "long";
import { useAppSelector } from "@axvdex/state";
import { postFeeGrantTx } from "@axvdex/api/user";
import { selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import { getFeeGrantSigners } from "@axvdex/state/wallet/walletThunks";

import Button from "./common/Button";

interface DashboardNavItemsProps {
  hideText?: boolean;
  hideIndex?: number;
}

function NavDebugItems(_props: DashboardNavItemsProps) {
  const walletInfo = useAppSelector(selectWalletInfo);

  return (
    <div className="navSidebarMenu">
    </div>
  );
}

export default NavDebugItems;
